import * as olms from "ol-mapbox-style/dist";
import BaseLayer from "ol/layer/Base";
import LayerGroup from "ol/layer/Group";
import TileLayer from "ol/layer/Tile";
import VectorTileLayer from "ol/layer/VectorTile";
import TileJSON from "ol/source/TileJSON";
import View from "ol/View";
import { SwitchBaseLayerControl, SwitchLayersControl } from "../controls";
import { profileConfig } from "../environment/profile.config";
import { replaceAccessToken } from "../helpers/source-helper";
import {
  SigmaTileJSONLayers,
  SigmaTileLayers,
  SigmaVectorLayers,
  SigmaVectorTileSource,
  SigmaWMTSSource,
  SigmaXYZSource
} from "../layers";
import { SigmaProfiles } from "../profiles.enum";
import {
  Configuration,
  SigmaOLMSConfiguration
} from "../typings/configs.types";
import mergeConfigurations from "../utils/mergeConfigurations";
import { SigmaMap } from "./sigma-map";
import { Control } from "ol/control";
import { createImgElementForLegend } from "../helpers/dom-helper";

function waitRenderComplete(map: SigmaMap): Promise<void> {
  return new Promise(r => {
    map.on("rendercomplete", () => r());
  });
}

/**
 * Create a sigmaMap according defined profile
 *
 * @param profile
 * @param configuration
 */
export function createSigmaMap(
  profile: SigmaProfiles,
  configuration?: Configuration
): SigmaMap {
  let config = mergeConfigurations(configuration);

  let sigmaMap = new SigmaMap(config.mapOptions);
  sigmaMap.setView(new View(config.viewOptions));

  if (!Object.keys(SigmaProfiles).includes(profile)) {
    console.warn(`Unknown profile <${profile}>`);
    console.warn(`Default to a SigmaMap with a simple base plan layer`);
    profile = SigmaProfiles.UNKNOWN;
  }

  // base plan layer
  let planLayer;
  if (
    profileConfig[profile].baseTiles.includes(SigmaTileLayers.PLAN) &&
    config.baseTiles &&
    config.baseTiles[SigmaTileLayers.PLAN]
  ) {
    planLayer = new TileLayer(
      config.baseTiles[SigmaTileLayers.PLAN].layerOptions
    );
    planLayer.setSource(
      new SigmaXYZSource(config.baseTiles[SigmaTileLayers.PLAN].sourceOptions)
    );
    sigmaMap.addLayer(planLayer);
  }

  // base ign layer
  let ignLayerGroup;
  if (
    profileConfig[profile].baseTiles.includes(SigmaTileLayers.IGN) &&
    config.baseTiles &&
    config.baseTiles[SigmaTileLayers.IGN]
  ) {
    const ignLayer = new TileLayer(
      config.baseTiles[SigmaTileLayers.IGN].layerOptions
    );
    ignLayer.setSource(
      new SigmaWMTSSource(config.baseTiles[SigmaTileLayers.IGN].sourceOptions)
    );
    let ignGroupLayers: BaseLayer[] = [
      ignLayer,
      // TODO put in configuration.
      new TileLayer({
        source: new TileJSON({
          url: `https://api.maptiler.com/maps/e61dda39-acec-442e-ab42-d004906234cf/256/tiles.json?key=qkH5rAQKNXqO6PLgwhs6`,
          crossOrigin: "anonymous"
        }),
        zIndex: 5,
        visible: true
      })
    ];
    ignLayerGroup = new LayerGroup({
      layers: ignGroupLayers
    });
  }

  // base market layer
  let marketLayer;
  if (
    profileConfig[profile].baseTiles.includes(SigmaTileLayers.MARKET) &&
    config.baseTiles &&
    config.baseTiles[SigmaTileLayers.MARKET]
  ) {
    marketLayer = new TileLayer(
      config.baseTiles[SigmaTileLayers.MARKET].layerOptions
    );
    marketLayer.setSource(
      new SigmaXYZSource(config.baseTiles[SigmaTileLayers.MARKET].sourceOptions)
    );
    sigmaMap.addLayer(marketLayer);
  }

  // base arcep layer with olms
  if (
    profileConfig[profile].baseTiles.includes(SigmaTileLayers.ARCEP) &&
    config.baseTiles &&
    config.baseTiles[SigmaTileLayers.ARCEP]
  ) {
    const arcepConf = config.baseTiles[SigmaTileLayers.ARCEP];
    const arcepUrl =
      arcepConf.sourceOptions && arcepConf.sourceOptions.url
        ? arcepConf.sourceOptions.url
        : "";
    const arcepAccessToken =
      arcepConf.sourceOptions && arcepConf.sourceOptions.accessToken
        ? arcepConf.sourceOptions.accessToken
        : "";
    olms.apply(sigmaMap, replaceAccessToken(arcepUrl, arcepAccessToken));
  }

  // add base controls to switch between base layers
  if (planLayer && ignLayerGroup) {
    const switchLayerControl = new SwitchBaseLayerControl(
      planLayer,
      ignLayerGroup
    );
    sigmaMap.addControl(switchLayerControl);
  }

  const vectorTiles = profileConfig[profile].vectorTiles;
  if (vectorTiles && vectorTiles.length > 0) {
    // add specific layers
    const switchLayersControl = new SwitchLayersControl();
    sigmaMap.addControl(switchLayersControl);
    vectorTiles.forEach((vectorLayer: SigmaVectorLayers) => {
      if (config.vectorTiles && config.vectorTiles[vectorLayer]) {
        sigmaMap.addSigmaVectorTileLayer(
          vectorLayer,
          config.vectorTiles[vectorLayer]
        );
      }
    });
  }

  const jsonTiles = profileConfig[profile].jsonTiles;
  if (jsonTiles && jsonTiles.length > 0) {
    jsonTiles.forEach((tileJSONLayer: SigmaTileJSONLayers) => {
      if (config.jsonTiles && config.jsonTiles[tileJSONLayer]) {
        sigmaMap.addSigmaTileJSONLayer(
          tileJSONLayer,
          config.jsonTiles[tileJSONLayer]
        );
      }
    });
  }

  const legend = config.legend;
  if (legend) {
    const legendControl = new Control({
      element: createImgElementForLegend(legend.url)
    });
    legendControl.setMap(sigmaMap);
  }

  const olmStyles = profileConfig[profile].olms;
  if (olmStyles && olmStyles.length) {
    olmStyles.forEach(async olmsStyle => {
      const olmsStyleConfig = (config.olms &&
        config.olms[olmsStyle]) as SigmaOLMSConfiguration;
      if (olmsStyleConfig) {
        const replacedAccessTokenUrl = replaceAccessToken(
          olmsStyleConfig.url,
          olmsStyleConfig.accessToken
        );
        return fetch(replacedAccessTokenUrl).then(async response => {
          const mapboxStyle = await response.json();
          const view = sigmaMap.getView();
          let layers: VectorTileLayer[] = [];
          if (olmsStyleConfig.layersFilter) {
            // when displayed olms displayed layers are filtered and customized.
            layers = await olmsStyleConfig.layersFilter.reduce(
              async (lp: Promise<VectorTileLayer[]>, lf) => {
                const l = await lp;
                const vectorSource = new SigmaVectorTileSource({
                  ...lf.sourceOptions,
                  // override access token
                  accessToken: olmsStyleConfig.accessToken
                });
                const declutterOnVectorLayer = new VectorTileLayer(
                  lf.layerOptions
                );
                const declutterOffVectorLayer = new VectorTileLayer({
                  ...lf.layerOptions,
                  declutter: false
                });

                declutterOnVectorLayer.setSource(vectorSource);
                declutterOffVectorLayer.setSource(vectorSource);

                const applyStylesPromises: Promise<void>[] = [
                  olms.applyStyle(
                    declutterOnVectorLayer,
                    mapboxStyle,
                    lf.styleLayers
                  )
                ];

                // handle zoom intervall
                if (lf.zoomInterval) {
                  const [minZoom, maxZoom] = lf.zoomInterval;
                  declutterOnVectorLayer.setMinResolution(
                    view.getResolutionForZoom(maxZoom)
                  );
                  declutterOnVectorLayer.setMaxResolution(
                    view.getResolutionForZoom(minZoom)
                  );
                  declutterOffVectorLayer.setMinResolution(
                    view.getResolutionForZoom(maxZoom)
                  );
                  declutterOffVectorLayer.setMaxResolution(
                    view.getResolutionForZoom(minZoom)
                  );
                }

                // handle declutter threshold
                const declutterThreshold = lf.declutterThreshold;
                if (declutterThreshold) {
                  declutterOnVectorLayer.setMinResolution(
                    view.getResolutionForZoom(declutterThreshold)
                  );
                  declutterOffVectorLayer.setMaxResolution(
                    view.getResolutionForZoom(declutterThreshold)
                  );
                  applyStylesPromises.push(
                    olms.applyStyle(
                      declutterOffVectorLayer,
                      mapboxStyle,
                      lf.styleLayers
                    )
                  );
                }
                await Promise.all(applyStylesPromises);
                return Promise.resolve([
                  ...l,
                  declutterOnVectorLayer,
                  declutterOffVectorLayer
                ]);
              },
              Promise.resolve([])
            );
          } else {
            // when use all style.json layers
            await olms.apply(sigmaMap, mapboxStyle);
            await waitRenderComplete(sigmaMap);
            layers = mapboxStyle.layers.reduce((la, sl) => {
              const l = olms.getLayer(sigmaMap, sl.id);
              return l ? la.concat(l) : la;
            }, []);
            // remove layers to put in group
            layers.forEach(l => {
              sigmaMap.removeLayer(l);
              if (olmsStyleConfig && olmsStyleConfig.displayOptions) {
                l.setZIndex(olmsStyleConfig.displayOptions.zIndex);
              }
            });
          }
          const layerGroup = new LayerGroup({ layers });
          layerGroup.set("name", olmsStyle);
          sigmaMap.addLayer(layerGroup);
          if (
            olmsStyleConfig &&
            olmsStyleConfig.layerOptions &&
            olmsStyleConfig.layerOptions.visible === false
          ) {
            layerGroup.setVisible(false);
          }
          sigmaMap.addLayerSwitchControl(olmsStyle, olmsStyleConfig);
        });
      } else {
        throw new Error(`No configuration found for style : ${olmsStyle}`);
      }
    });
  }

  return sigmaMap;
}
