import MVT from "ol/format/MVT";
import VectorTileSource from "ol/source/VectorTile";
import { replaceAccessToken } from "../../../helpers/source-helper";
import { SigmaVectorTileSourceOptions } from "../../../typings/options.types";

export class SigmaVectorTileSource extends VectorTileSource {
  public constructor(options?: SigmaVectorTileSourceOptions) {
    if (options && options.url && options.accessToken) {
      options.url = replaceAccessToken(options.url, options.accessToken);
    }
    super({
      format: new MVT(),
      ...options
    });
  }
}
