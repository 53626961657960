/**
 * Replace access token in url.
 *
 * @param url string
 * @param accessToken string
 */
export function replaceAccessToken(url: string, accessToken: string): string {
  if (url && accessToken) {
    return url.replace(/\{accessToken\}/g, accessToken);
  }
  return url;
}
