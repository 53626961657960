import TileLayer, { Options } from "ol/layer/Tile";
import { SigmaTileJSONLayers } from "./sigma-tile-json-layers.enum";

export class SigmaTileLayer extends TileLayer {
  public selectedFeatures: (number | string)[] = [];
  public constructor(name: SigmaTileJSONLayers, options?: Options) {
    super(options);
    this.set("name", name);
  }
}
