import VectorTileLayer, { Options } from "ol/layer/VectorTile";
import { SigmaVectorLayers } from "./sigma-vector-layers.enum";
import {
  SelectedPointStyle,
  SelectedShapeStyle,
  ShapeStyle,
  PointStyle
} from "../../styles";

export class SigmaVectorTileLayer extends VectorTileLayer {
  public selectedFeatures: (number | string)[] = [];
  public constructor(name: SigmaVectorLayers, options?: Options) {
    super({
      declutter: true,
      style: feature => {
        return this.selectedFeatures.indexOf(feature.getId()) >= 0
          ? [SelectedPointStyle, SelectedShapeStyle]
          : [PointStyle, ShapeStyle];
      },
      ...options
    });
    this.set("name", name);
  }
}
