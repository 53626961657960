/**
 * ol packages.
 */
import * as olControls from "ol/control";
import * as formatFeature from "ol/format/Feature";
import * as formatPolyLine from "ol/format/Polyline";
import * as formats from "ol/format";
import * as interaction from "ol/interaction";
import * as olLayers from "ol/layer";
import * as proj from "ol/proj";
import * as olSources from "ol/source";
import * as style from "ol/style";
import * as geom from "ol/geom";
import * as extent from "ol/extent";

/**
 * ol Objects.
 */
import Geolocation from "ol/Geolocation";
import Feature from "ol/Feature";
import Map from "ol/Map";
import Object from "ol/Object";
import Overlay from "ol/Overlay";
import View from "ol/View";
import Collection from "ol/Collection";

/**
 * sigma
 */
import * as sigmaControls from "./controls";
import * as Maps from "./maps";
import {
  SigmaXYZSource,
  SigmaWMTSSource,
  SigmaTileLayers,
  SigmaVectorTileSource,
  SigmaVectorTileLayer,
  SigmaVectorLayers
} from "./layers";

/**
 * default config
 */

import { config as defaultConfig } from "./environment/config";

/**
 * OLMS
 */
import * as olms from "ol-mapbox-style/dist";

/* Import style to bundle them */
import "./styles/ol.css";
import "./styles/main.css";

export default {
  // sigma Objects
  ...Maps,
  // merge ol / sigma
  layer: {
    SigmaTileLayers,
    SigmaVectorTileLayer,
    SigmaVectorLayers,
    ...olLayers
  },
  source: {
    SigmaXYZSource,
    SigmaWMTSSource,
    SigmaVectorTileSource,
    ...olSources
  },
  control: {
    ...sigmaControls,
    ...olControls
  },
  format: {
    ...formats,
    ...formatFeature,
    ...formatPolyLine
  },
  //ol packages
  proj,
  interaction,
  style,
  geom,
  // ol objects
  Map,
  View,
  Object,
  Overlay,
  Geolocation,
  Feature,
  Collection,
  defaultConfig,
  olms,
  extent
};
