import Control, { Options } from "ol/control/Control";
import { createCheckboxButtonWithLabel } from "../helpers/dom-helper";

const element = document.createElement("div");

export class SwitchLayersControl extends Control {
  public constructor(options?: Options) {
    element.className = "ol-switch-layers ol-unselectable ol-control";
    super({
      element,
      ...options
    });
  }

  public addControlLayer(
    layerName: string,
    label: string | null,
    visibility: boolean
  ) {
    const cbLayer = createCheckboxButtonWithLabel(
      layerName,
      label || layerName,
      visibility
    );
    element.appendChild(cbLayer);

    cbLayer.addEventListener(
      "change",
      this.switchLayerVisibility.bind(this, layerName),
      false
    );
  }

  private switchLayerVisibility(layerName: string) {
    const layers = this.getMap()
      .getLayers()
      .getArray();

    let layerFound = false;
    layers.forEach(layer => {
      if (layer.get("name") === layerName) {
        layerFound = true;
        layer.setVisible(!layer.getVisible());
      }
    });

    if (!layerFound) {
      let el = document.getElementById(layerName) as HTMLInputElement;
      if (el) {
        el.checked = !el.checked;
      }
    }
  }
}
