import { SigmaOLMSStyles, SigmaTileLayers, SigmaVectorLayers } from "../layers";
import { SigmaProfiles } from "../profiles.enum";
import { ProfileConfiguration } from "../typings/configs.types";

export const profileConfig = {
  [SigmaProfiles.DEMO]: {
    baseTiles: [SigmaTileLayers.PLAN, SigmaTileLayers.IGN],
    vectorTiles: [
      SigmaVectorLayers.CADASTRE,
      SigmaVectorLayers.FIBRE_OUVERT_COMMUNES,
      SigmaVectorLayers.FIBRE_PLANIFIE_COMMUNES,
      SigmaVectorLayers.RADIO_OUVERT_COMMUNES,
      SigmaVectorLayers.RADIO_PLANIFIE_COMMUNES,
      SigmaVectorLayers.PARTENAIRES_CERTIFIES_FIBRE,
      SigmaVectorLayers.PARTENAIRES_NON_CERTIFIES,
      SigmaVectorLayers.VILLES_SUBVENTION_ETAT,
      SigmaVectorLayers.AMBASSADEURS
    ],
    olms: [
      SigmaOLMSStyles.FIBRE_IPE,
      SigmaOLMSStyles.SIGMA_POI_ADP,
      SigmaOLMSStyles.RADIO_COUVERTURE_AMENAGEURS
    ]
  },
  [SigmaProfiles.UNKNOWN]: {
    baseTiles: [SigmaTileLayers.PLAN],
    vectorTiles: []
  },
  [SigmaProfiles.ARCEP]: {
    baseTiles: [SigmaTileLayers.ARCEP],
    vectorTiles: []
  },
  [SigmaProfiles.CARE]: {
    baseTiles: [SigmaTileLayers.PLAN, SigmaTileLayers.IGN],
    vectorTiles: [SigmaVectorLayers.CADASTRE, SigmaVectorLayers.ORANGE_4G],
    olms: [
      SigmaOLMSStyles.FIBRE_IPE,
      SigmaOLMSStyles.SIGMA_POI_ADP,
      SigmaOLMSStyles.RADIO_COUVERTURE_AMENAGEURS
    ]
  },
  [SigmaProfiles.ELIGIBILITY]: {
    baseTiles: [SigmaTileLayers.PLAN, SigmaTileLayers.IGN],
    olms: [
      SigmaOLMSStyles.SIGMA_POI_ADP_CLIENT,
      SigmaOLMSStyles.FIBRE_IPE_CLIENT
    ]
  },
  [SigmaProfiles.MARKET]: {
    baseTiles: [SigmaTileLayers.MARKET],
    vectorTiles: [
      SigmaVectorLayers.FIBRE_OUVERT_COMMUNES,
      SigmaVectorLayers.FIBRE_PLANIFIE_COMMUNES,
      SigmaVectorLayers.RADIO_OUVERT_COMMUNES,
      SigmaVectorLayers.RADIO_PLANIFIE_COMMUNES,
      SigmaVectorLayers.PARTENAIRES_CERTIFIES_FIBRE,
      SigmaVectorLayers.PARTENAIRES_NON_CERTIFIES,
      SigmaVectorLayers.VILLES_SUBVENTION_ETAT,
      SigmaVectorLayers.AMBASSADEURS,
      SigmaVectorLayers.DEPARTEMENTS_BLANCS,
      SigmaVectorLayers.COMMUNES_BLANCHES_FROIDES,
      SigmaVectorLayers.COMMUNES_BLANCHES_CHAUDES
    ]
  },
  [SigmaProfiles.PARTNERS]: {
    baseTiles: [SigmaTileLayers.PLAN, SigmaTileLayers.IGN],
    vectorTiles: [
      SigmaVectorLayers.FIBRE_OUVERT_COMMUNES_CLIENT,
      SigmaVectorLayers.FIBRE_PLANIFIE_COMMUNES_CLIENT
    ]
  },
  [SigmaProfiles.POSITION]: {
    baseTiles: [SigmaTileLayers.PLAN, SigmaTileLayers.IGN],
    vectorTiles: []
  },
  [SigmaProfiles.SCOUT]: {
    baseTiles: [SigmaTileLayers.PLAN],
    vectorTiles: []
  },
  [SigmaProfiles.AUTOLOC]: {
    baseTiles: [SigmaTileLayers.PLAN, SigmaTileLayers.IGN],
    olms: [SigmaOLMSStyles.FIBRE_IPE_CLIENT]
  }
} as ProfileConfiguration;
