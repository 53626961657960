import Circle from "ol/style/Circle";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import Text from "ol/style/Text";

export const ShapeStyle = new Style({
  fill: new Fill({
    color: "rgba(255, 255, 255, 0.5)"
  }),
  stroke: new Stroke({
    color: "rgba(51, 153, 204, 1)",
    width: 1
  }),
  text: new Text({
    font: "12px Calibri,sans-serif",
    fill: new Fill({
      color: "#000"
    }),
    stroke: new Stroke({
      color: "#fff",
      width: 3
    })
  })
});

export const PointStyle = new Style({
  image: new Circle({
    fill: new Fill({
      color: "rgba(51, 153, 204, 0.3)"
    }),
    radius: 1,
    stroke: new Stroke({
      color: "rgba(51, 153, 204, 1)",
      width: 1
    })
  })
});

export const SelectedPointStyle = new Style({
  image: new Circle({
    fill: new Fill({
      color: "rgba(200, 20, 20, 0.4)"
    }),
    radius: 5,
    stroke: new Stroke({
      color: "rgba(200, 20, 20, 0.8)",
      width: 1
    })
  })
});

export const SelectedShapeStyle = new Style({
  fill: new Fill({
    color: "rgba(200, 20, 20, 0.4)"
  }),
  stroke: new Stroke({
    color: "rgba(200, 20, 20, 0.8)",
    width: 1
  }),
  text: new Text({
    font: "12px Calibri,sans-serif",
    fill: new Fill({
      color: "#000"
    }),
    stroke: new Stroke({
      color: "#fff",
      width: 3
    })
  })
});
