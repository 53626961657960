import Map from "ol/Map.js";
import { MapOptions } from "ol/PluggableMap";
import { SwitchLayersControl } from "../controls";
import { SigmaVectorLayers } from "../layers/vector/sigma-vector-layers.enum";
import {
  SigmaVectorTileLayer,
  SigmaVectorTileSource,
  SigmaTileJSONLayers,
  SigmaTileLayer,
  SigmaTileJSONSource,
  SigmaOLMSStyles
} from "../layers";
import {
  SigmaVectorTileConfiguration,
  SigmaTileJSONConfiguration,
  SigmaOLMSConfiguration
} from "../typings/configs.types";

export class SigmaMap extends Map {
  public constructor(mapOptions?: MapOptions) {
    super({
      layers: [],
      ...mapOptions
    });
  }

  public getSigmaVectorTileLayer(
    name: SigmaVectorLayers
  ): SigmaVectorTileLayer | undefined {
    const layers = this.getLayers().getArray();
    return layers.find(l => l.get("name") === name) as SigmaVectorTileLayer;
  }

  public addSigmaVectorTileLayer(
    name: SigmaVectorLayers,
    configuration: SigmaVectorTileConfiguration
  ) {
    let layer = new SigmaVectorTileLayer(name, configuration.layerOptions);
    layer.setSource(new SigmaVectorTileSource(configuration.sourceOptions));

    if (configuration.zoomInterval) {
      layer.setMinResolution(
        this.getView().getResolutionForZoom(configuration.zoomInterval[1])
      );
    }
    if (configuration.zoomInterval) {
      layer.setMaxResolution(
        this.getView().getResolutionForZoom(configuration.zoomInterval[0])
      );
    }

    this.addLayer(layer);

    this.addLayerSwitchControl(name, configuration);
  }

  public addSigmaTileJSONLayer(
    name: SigmaTileJSONLayers,
    configuration: SigmaTileJSONConfiguration
  ) {
    let layer = new SigmaTileLayer(name, configuration.layerOptions);
    layer.setSource(new SigmaTileJSONSource(configuration.sourceOptions));
    this.addLayer(layer);
    this.addLayerSwitchControl(name, configuration);
  }

  public addLayerSwitchControl(
    name: SigmaVectorLayers | SigmaTileJSONLayers | SigmaOLMSStyles,
    configuration:
      | SigmaVectorTileConfiguration
      | SigmaTileJSONConfiguration
      | SigmaOLMSConfiguration
  ) {
    const switchLayersControl = this.getControls()
      .getArray()
      .find(c => c instanceof SwitchLayersControl) as SwitchLayersControl;
    if (switchLayersControl) {
      switchLayersControl.addControlLayer(
        name,
        configuration.displayOptions
          ? configuration.displayOptions.label
          : null,
        configuration.layerOptions &&
          typeof configuration.layerOptions.visible === "boolean"
          ? configuration.layerOptions.visible
          : true
      );
    }
  }
}
