import { SigmaOLMSStyles, SigmaTileLayers, SigmaVectorLayers } from "../layers";

const maptilerUrl = `https://api.maptiler.com/`;
const apimKongUrl = `https://apim.kong.nordnet.fr:8443/`;
const apimPubKongUrl = `https://apim-pub.nordnet.fr/`;

export const environment = {
  [SigmaTileLayers.PLAN]: {
    url: `${maptilerUrl}maps/f23485a4-fe55-42d8-876d-6865e9ae428f/256/{z}/{x}/{y}.png?key={accessToken}`
  },
  [SigmaTileLayers.IGN]: {
    url: `https://data.geopf.fr/wmts`
  },
  [SigmaTileLayers.MARKET]: {
    url: `${maptilerUrl}maps/38f674ee-bee8-4db4-b6da-a5ca750a7971/{z}/{x}/{y}.png?key={accessToken}`
  },
  [SigmaTileLayers.ARCEP]: {
    url: `${maptilerUrl}maps/00917b02-ee71-453e-9d18-b5f14103a5bf/style.json?key={accessToken}`
  },
  [SigmaVectorLayers.CADASTRE]: {
    url: `${maptilerUrl}tiles/91f0a833-1f61-466b-af60-c97d1edca041/{z}/{x}/{y}.pbf?key={accessToken}`
  },

  [SigmaVectorLayers.FIBRE_OUVERT_COMMUNES]: {
    url: `${apimKongUrl}tileserver-priv-data/956c4d74-fef1-43f5-a6f9-c22adab798cf/{z}/{x}/{y}.pbf?apikey={accessToken}`
  },
  [SigmaVectorLayers.FIBRE_OUVERT_COMMUNES_CLIENT]: {
    url: `${apimPubKongUrl}tileserver-pub-data/956c4d74-fef1-43f5-a6f9-c22adab798cf/{z}/{x}/{y}.pbf?apikey={accessToken}`
  },
  [SigmaVectorLayers.FIBRE_PLANIFIE_COMMUNES]: {
    url: `${apimKongUrl}tileserver-priv-data/99a589fc-552a-423b-928e-d2ff4f6272c1/{z}/{x}/{y}.pbf?apikey={accessToken}`
  },
  [SigmaVectorLayers.FIBRE_PLANIFIE_COMMUNES_CLIENT]: {
    url: `${apimPubKongUrl}tileserver-pub-data/99a589fc-552a-423b-928e-d2ff4f6272c1/{z}/{x}/{y}.pbf?apikey={accessToken}`
  },
  [SigmaVectorLayers.RADIO_OUVERT_COMMUNES]: {
    url: `${apimKongUrl}tileserver-priv-data/b825fb3b-4ea4-4384-9013-5417097fcaec/{z}/{x}/{y}.pbf?apikey={accessToken}`
  },
  [SigmaVectorLayers.RADIO_PLANIFIE_COMMUNES]: {
    url: `${apimKongUrl}tileserver-priv-data/97d4db6c-4f1d-4be0-b382-5f4c001561c4/{z}/{x}/{y}.pbf?apikey={accessToken}`
  },
  [SigmaVectorLayers.PARTENAIRES_CERTIFIES_FIBRE]: {
    url: `${apimKongUrl}tileserver-priv-data/4710e809-837a-40a3-8628-af8339ed7595/{z}/{x}/{y}.pbf?apikey={accessToken}`
  },
  [SigmaVectorLayers.PARTENAIRES_NON_CERTIFIES]: {
    url: `${apimKongUrl}tileserver-priv-data/76d9e8ab-d1ca-4e76-92d4-a6bead558f05/{z}/{x}/{y}.pbf?apikey={accessToken}`
  },
  [SigmaVectorLayers.VILLES_SUBVENTION_ETAT]: {
    url: `${apimKongUrl}tileserver-priv-data/f21936cd-d4b9-4791-8d1f-7c00d3b82f76/{z}/{x}/{y}.pbf?apikey={accessToken}`
  },
  [SigmaVectorLayers.AMBASSADEURS]: {
    url: `${apimKongUrl}tileserver-priv-data/3da9bac4-9265-4990-a8d3-892dad0c0816/{z}/{x}/{y}.pbf?apikey={accessToken}`
  },
  [SigmaVectorLayers.DEPARTEMENTS_BLANCS]: {
    url: `${apimKongUrl}tileserver-priv-data/44fd9e0d-3109-4eb9-bc72-cbe6664ec159/{z}/{x}/{y}.pbf?apikey={accessToken}`
  },
  [SigmaVectorLayers.COMMUNES_BLANCHES_FROIDES]: {
    url: `${apimKongUrl}tileserver-priv-data/9e79a007-abff-4baf-9bc0-8bbdad9f3d5c/{z}/{x}/{y}.pbf?apikey={accessToken}`
  },
  [SigmaVectorLayers.COMMUNES_BLANCHES_CHAUDES]: {
    url: `${apimKongUrl}tileserver-priv-data/732dff46-92b6-4563-9822-a1d3ba0fa5fd/{z}/{x}/{y}.pbf?apikey={accessToken}`
  },
  [SigmaVectorLayers.ORANGE_4G]: {
    url: `${apimKongUrl}tileserver-priv-data/d5763b74-0097-4bf7-9db8-8015f97d1f2d/{z}/{x}/{y}.pbf?apikey={accessToken}`
  },
  [SigmaOLMSStyles.FIBRE_IPE]: {
    styleUrl: `${apimKongUrl}tileserver-priv-styles/sigma-poi-fibre-ipe/style.json?apikey={accessToken}`,
    layerUrl: `${apimKongUrl}tileserver-priv-data/9181ed3a-2232-4fb9-a92c-ebd2e01ee1c0/{z}/{x}/{y}.pbf?apikey={accessToken}`
  },
  [SigmaOLMSStyles.RADIO_COUVERTURE_AMENAGEURS]: {
    styleUrl: `${apimKongUrl}tileserver-priv-styles/sigma-care-couverture-radio/style.json?apikey={accessToken}`
  },
  [SigmaOLMSStyles.FIBRE_IPE_CLIENT]: {
    styleUrl: `${apimPubKongUrl}tileserver-pub-styles/sigma-poi-fibre-ipe-client/style.json?apikey={accessToken}`
  },
  [SigmaOLMSStyles.SIGMA_POI_ADP]: {
    styleUrl: `${apimKongUrl}tileserver-priv-styles/sigma-poi-adp/style.json?apikey={accessToken}`
  },
  [SigmaOLMSStyles.SIGMA_POI_ADP_CLIENT]: {
    styleUrl: `${apimPubKongUrl}tileserver-pub-styles/sigma-poi-adp-client/style.json?apikey={accessToken}`
  }
};
