import { Configuration } from "../typings/configs.types";
import { config as defaultConfiguration } from "../environment/config";
import { SigmaTileLayers, SigmaVectorLayers } from "../layers";

/**
 * Simple is object check.
 * @param item
 * @returns {boolean}
 */
function isObject(item) {
  return (
    item && typeof item === "object" && !Array.isArray(item) && item !== null
  );
}

/**
 * Deep merge two objects.
 * @param target
 * @param source
 */
function mergeDeep(target, source) {
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach(key => {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    });
  }
  return target;
}

export default function mergeConfigurations(config?: Configuration) {
  if (!config) {
    return defaultConfiguration;
  }
  // TODO use mergeDeep for all configs ?
  let mergedConfig: Configuration = {
    mapOptions: {
      ...defaultConfiguration.mapOptions,
      ...config.mapOptions
    },
    viewOptions: {
      ...defaultConfiguration.viewOptions,
      ...config.viewOptions
    },
    baseTiles: { ...defaultConfiguration.baseTiles },
    vectorTiles: { ...defaultConfiguration.vectorTiles },
    jsonTiles: mergeDeep(defaultConfiguration.jsonTiles, config.jsonTiles),
    olms: mergeDeep(defaultConfiguration.olms, config.olms),
    legend: config.legend
  };

  for (const key of Object.keys(defaultConfiguration.baseTiles)) {
    if (
      mergedConfig.baseTiles &&
      config.baseTiles &&
      config.baseTiles[key as SigmaTileLayers]
    ) {
      if (config.baseTiles[key as SigmaTileLayers].layerOptions) {
        mergedConfig.baseTiles[key as SigmaTileLayers].layerOptions = {
          ...defaultConfiguration.baseTiles[key as SigmaTileLayers]
            .layerOptions,
          ...config.baseTiles[key as SigmaTileLayers].layerOptions
        };
      }
      if (config.baseTiles[key as SigmaTileLayers].sourceOptions) {
        mergedConfig.baseTiles[key as SigmaTileLayers].sourceOptions = {
          ...defaultConfiguration.baseTiles[key as SigmaTileLayers]
            .sourceOptions,
          ...config.baseTiles[key as SigmaTileLayers].sourceOptions
        };
      }
    }
  }

  for (const key of Object.keys(defaultConfiguration.vectorTiles)) {
    if (
      mergedConfig.vectorTiles &&
      config.vectorTiles &&
      config.vectorTiles[key as SigmaVectorLayers]
    ) {
      mergedConfig.vectorTiles[key as SigmaVectorLayers] = {
        ...defaultConfiguration.vectorTiles[key as SigmaVectorLayers],
        ...config.vectorTiles[key as SigmaVectorLayers]
      };
      if (config.vectorTiles[key as SigmaVectorLayers].layerOptions) {
        mergedConfig.vectorTiles[key as SigmaVectorLayers].layerOptions = {
          ...defaultConfiguration.vectorTiles[key as SigmaVectorLayers]
            .layerOptions,
          ...config.vectorTiles[key as SigmaVectorLayers].layerOptions
        };
      }
      if (config.vectorTiles[key as SigmaVectorLayers].sourceOptions) {
        mergedConfig.vectorTiles[key as SigmaVectorLayers].sourceOptions = {
          ...defaultConfiguration.vectorTiles[key as SigmaVectorLayers]
            .sourceOptions,
          ...config.vectorTiles[key as SigmaVectorLayers].sourceOptions
        };
      }
      if (config.vectorTiles[key as SigmaVectorLayers].displayOptions) {
        mergedConfig.vectorTiles[key as SigmaVectorLayers].displayOptions = {
          ...defaultConfiguration.vectorTiles[key as SigmaVectorLayers]
            .displayOptions,
          ...config.vectorTiles[key as SigmaVectorLayers].displayOptions
        };
      }
    }
  }

  return mergedConfig;
}
