const DIV_ELEMENT = "div";
const LABEL_ELEMENT = "label";
const INPUT_ELEMENT = "input";
const RADIO_TYPE = "radio";
const CHECKBOX_TYPE = "checkbox";

export function createRadioButtonWithLabel(
  name: string,
  value: string,
  label: string,
  checked?: boolean
): HTMLElement {
  const wrapperDiv = document.createElement(DIV_ELEMENT);
  const radioButton = document.createElement(INPUT_ELEMENT);
  radioButton.setAttribute("type", RADIO_TYPE);
  radioButton.setAttribute("value", value);
  radioButton.setAttribute("name", name);
  if (checked) {
    radioButton.setAttribute("checked", "");
  }
  const radioLabel = document.createElement(LABEL_ELEMENT);
  radioLabel.setAttribute("for", name);
  radioLabel.innerHTML = label;
  wrapperDiv.appendChild(radioButton);
  wrapperDiv.appendChild(radioLabel);
  return wrapperDiv;
}

export function createCheckboxButtonWithLabel(
  id: string,
  label: string,
  checked?: boolean
): HTMLElement {
  const wrapperDiv = document.createElement(DIV_ELEMENT);
  const checkboxButton = document.createElement(INPUT_ELEMENT);
  checkboxButton.setAttribute("type", CHECKBOX_TYPE);
  checkboxButton.setAttribute("id", id);
  if (checked) {
    checkboxButton.setAttribute("checked", "");
  }
  const checkboxLabel = document.createElement(LABEL_ELEMENT);
  checkboxLabel.setAttribute("for", id);
  checkboxLabel.innerHTML = label;
  wrapperDiv.appendChild(checkboxButton);
  wrapperDiv.appendChild(checkboxLabel);
  return wrapperDiv;
}

export function createImgElementForLegend(url: string) {
  const wrapperDiv = document.createElement(DIV_ELEMENT);
  const img = document.createElement("img");
  wrapperDiv.classList.add("ol-unselectable");
  wrapperDiv.id = "sigma-legend";
  wrapperDiv.style.position = "absolute";
  wrapperDiv.style.bottom = "30px";
  wrapperDiv.style.left = "8px";

  img.setAttribute("src", url);

  wrapperDiv.appendChild(img);
  return wrapperDiv;
}
