import XYZ, { Options } from "ol/source/XYZ";
import { replaceAccessToken } from "../../../helpers/source-helper";
import { SigmaXYZSourceOptions } from "../../../typings/options.types";

export class SigmaXYZSource extends XYZ {
  public constructor(options?: SigmaXYZSourceOptions) {
    if (options && options.url && options.accessToken) {
      options.url = replaceAccessToken(options.url, options.accessToken);
    }
    super(options as Options);
  }
}
