export enum SigmaProfiles {
  CARE = "CARE",
  ELIGIBILITY = "ELIGIBILITY",
  MARKET = "MARKET",
  PARTNERS = "PARTNERS",
  ARCEP = "ARCEP",
  POSITION = "POSITION",
  SCOUT = "SCOUT",
  UNKNOWN = "UNKNOWN",
  AUTOLOC = "AUTOLOC",
  DEMO = "DEMO"
}
