import Control, { Options } from "ol/control/Control";
import TileLayer from "ol/layer/Tile";
import LayerGroup from "ol/layer/Group";

const SATELLITE_LABEL = "Satellite";
const PLAN_LABEL = "Plan";

export class SwitchBaseLayerControl extends Control {
  private planLayer: TileLayer;
  private ignLayer: LayerGroup;

  public constructor(
    planLayer: TileLayer,
    ignLayer: LayerGroup,
    options?: Options
  ) {
    const buttonSatellite = document.createElement("button");
    buttonSatellite.setAttribute("id", "switchSat");
    buttonSatellite.innerText = SATELLITE_LABEL;

    const buttonPlan = document.createElement("button");
    buttonPlan.setAttribute("id", "switchPlan");
    buttonPlan.innerText = PLAN_LABEL;

    const element = document.createElement("div");
    element.className = "ol-switch-base-layer ol-unselectable ol-control";
    element.appendChild(buttonSatellite);
    element.appendChild(buttonPlan);

    super({
      element,
      ...options
    });

    this.planLayer = planLayer;
    this.ignLayer = ignLayer;

    buttonSatellite.addEventListener(
      "click",
      this.switchSatellite.bind(this),
      false
    );
    buttonPlan.addEventListener(
      "click",
      this.switchPlanLayer.bind(this),
      false
    );
  }

  private switchPlanLayer() {
    const map = this.getMap();
    const layers = this.getMap()
      .getLayers()
      .getArray();

    // add plan base layer
    if (layers.indexOf(this.planLayer) < 0) {
      map.addLayer(this.planLayer);
    }

    // remove ign base layer
    if (layers.indexOf(this.ignLayer) >= 0) {
      map.removeLayer(this.ignLayer);
    }
  }

  private switchSatellite() {
    const map = this.getMap();
    const layers = this.getMap()
      .getLayers()
      .getArray();

    // remove plan layer
    if (layers.indexOf(this.planLayer) >= 0) {
      map.removeLayer(this.planLayer);
    }

    // add ign base layer
    if (layers.indexOf(this.ignLayer) < 0) {
      map.addLayer(this.ignLayer);
    }
  }
}
