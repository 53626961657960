import { environment } from "@env/environment";
import { defaults as defaultControls, ScaleLine } from "ol/control";
import { fromLonLat, transformExtent } from "ol/proj";
import { createXYZ } from "ol/tilegrid";
import {
  SigmaOLMSStyles,
  SigmaTileJSONLayers,
  SigmaTileLayers,
  SigmaVectorLayers
} from "../layers";
import { IGNTileGrid } from "../layers/base/sources/tiles/ign-tilegrid";
import { Style, Fill, Stroke } from "ol/style";

export const config = {
  mapOptions: {
    target: "map",
    controls: defaultControls().extend([new ScaleLine({ units: "metric" })])
  },
  viewOptions: {
    center: fromLonLat([3.1280742, 50.647708]),
    zoom: 16,
    maxZoom: 19,
    minZoom: 5,
    extent: transformExtent(
      [-5.4534286, 41.2632185, 9.8678344, 52.25],
      "EPSG:4326",
      "EPSG:3857"
    )
  },
  baseTiles: {
    [SigmaTileLayers.PLAN]: {
      layerOptions: { zIndex: 0 },
      sourceOptions: {
        crossOrigin: "anonymous",
        url: environment[SigmaTileLayers.PLAN].url,
        accessToken: ``,
        attributions:
          "&copy; <a href='https://www.maptiler.com/copyright/' target='_blank'>MapTiler</a> &copy; <a href='http://www.openstreetmap.org/about/' target='_blank'>OpenStreetMap</a> contributors"
      }
    },
    [SigmaTileLayers.IGN]: {
      layerOptions: { zIndex: 0 },
      sourceOptions: {
        url: environment[SigmaTileLayers.IGN].url,
        /**
         * @deprecated access token is no more needed.
         */
        accessToken: ``,
        crossOrigin: "anonymous",
        attributions: "&copy; IGN - 2020 - copie et reproduction interdite",
        layer: "ORTHOIMAGERY.ORTHOPHOTOS",
        tileGrid: new IGNTileGrid(),
        matrixSet: "PM",
        format: "image/jpeg",
        style: "normal"
      }
    },
    [SigmaTileLayers.MARKET]: {
      layerOptions: { zIndex: 0 },
      sourceOptions: {
        crossOrigin: "anonymous",
        url: environment[SigmaTileLayers.MARKET].url,
        accessToken: ``,
        attributions:
          "&copy; <a href='https://www.maptiler.com/copyright/' target='_blank'>MapTiler</a> &copy; <a href='http://www.openstreetmap.org/about/' target='_blank'>OpenStreetMap</a> contributors"
      }
    },
    [SigmaTileLayers.ARCEP]: {
      layerOptions: { zIndex: 0 },
      sourceOptions: {
        crossOrigin: "anonymous",
        url: environment[SigmaTileLayers.ARCEP].url,
        accessToken: ``,
        attributions:
          "&copy; <a href='https://www.maptiler.com/copyright/' target='_blank'>MapTiler</a> &copy; <a href='http://www.openstreetmap.org/about/' target='_blank'>OpenStreetMap</a> contributors"
      }
    }
  },
  vectorTiles: {
    [SigmaVectorLayers.CADASTRE]: {
      layerOptions: { zIndex: 2 },
      sourceOptions: {
        url: environment[SigmaVectorLayers.CADASTRE].url,
        accessToken: ``,
        tileGrid: createXYZ({
          minZoom: 14,
          maxZoom: 15
        })
      },
      zoomInterval: [10, 24],
      displayOptions: {
        label: "Bâtiments (cadastre)"
      }
    },

    [SigmaVectorLayers.FIBRE_OUVERT_COMMUNES]: {
      layerOptions: { zIndex: 5 },
      sourceOptions: {
        url: environment[SigmaVectorLayers.FIBRE_OUVERT_COMMUNES].url,
        accessToken: ``,
        tileGrid: createXYZ({
          minZoom: 5,
          maxZoom: 12
        })
      },
      zoomInterval: [5, 15],
      displayOptions: {
        label: "Communes fibrées (ouvert)"
      }
    },
    [SigmaVectorLayers.FIBRE_OUVERT_COMMUNES_CLIENT]: {
      layerOptions: { zIndex: 5 },
      sourceOptions: {
        url: environment[SigmaVectorLayers.FIBRE_OUVERT_COMMUNES_CLIENT].url,
        accessToken: ``,
        tileGrid: createXYZ({
          minZoom: 5,
          maxZoom: 12
        })
      },
      zoomInterval: [5, 15],
      displayOptions: {
        label: "Communes fibrées (ouvert)"
      }
    },
    [SigmaVectorLayers.FIBRE_PLANIFIE_COMMUNES]: {
      layerOptions: { zIndex: 6 },
      sourceOptions: {
        url: environment[SigmaVectorLayers.FIBRE_PLANIFIE_COMMUNES].url,
        accessToken: ``,
        tileGrid: createXYZ({
          minZoom: 5,
          maxZoom: 12
        })
      },
      zoomInterval: [5, 15],
      displayOptions: {
        label: "Communes fibrées (planifié)"
      }
    },
    [SigmaVectorLayers.FIBRE_PLANIFIE_COMMUNES_CLIENT]: {
      layerOptions: { zIndex: 6 },
      sourceOptions: {
        url: environment[SigmaVectorLayers.FIBRE_PLANIFIE_COMMUNES_CLIENT].url,
        accessToken: ``,
        tileGrid: createXYZ({
          minZoom: 5,
          maxZoom: 12
        })
      },
      zoomInterval: [5, 15],
      displayOptions: {
        label: "Communes fibrées (planifié)"
      }
    },
    [SigmaVectorLayers.RADIO_OUVERT_COMMUNES]: {
      layerOptions: { zIndex: 8 },
      sourceOptions: {
        url: environment[SigmaVectorLayers.RADIO_OUVERT_COMMUNES].url,
        accessToken: ``,
        tileGrid: createXYZ({
          minZoom: 5,
          maxZoom: 12
        })
      },
      zoomInterval: [5, 15],
      displayOptions: {
        label: "Communes radio (ouvert)"
      }
    },
    [SigmaVectorLayers.RADIO_PLANIFIE_COMMUNES]: {
      layerOptions: { zIndex: 9 },
      sourceOptions: {
        url: environment[SigmaVectorLayers.RADIO_PLANIFIE_COMMUNES].url,
        accessToken: ``,
        tileGrid: createXYZ({
          minZoom: 5,
          maxZoom: 12
        })
      },
      zoomInterval: [5, 15],
      displayOptions: {
        label: "Communes radio (planifié)"
      }
    },
    [SigmaVectorLayers.PARTENAIRES_CERTIFIES_FIBRE]: {
      layerOptions: { zIndex: 11 },
      sourceOptions: {
        url: environment[SigmaVectorLayers.PARTENAIRES_CERTIFIES_FIBRE].url,
        accessToken: ``,
        tileGrid: createXYZ({
          minZoom: 5,
          maxZoom: 15
        })
      },
      zoomInterval: [5, 15],
      displayOptions: {
        label: "Partenaires certifiés fibre"
      }
    },
    [SigmaVectorLayers.PARTENAIRES_NON_CERTIFIES]: {
      layerOptions: { zIndex: 12 },
      sourceOptions: {
        url: environment[SigmaVectorLayers.PARTENAIRES_NON_CERTIFIES].url,
        accessToken: ``,
        tileGrid: createXYZ({
          minZoom: 5,
          maxZoom: 15
        })
      },
      zoomInterval: [5, 15],
      displayOptions: {
        label: "Partenaires non certifiés"
      }
    },
    [SigmaVectorLayers.VILLES_SUBVENTION_ETAT]: {
      layerOptions: { zIndex: 14 },
      sourceOptions: {
        url: environment[SigmaVectorLayers.VILLES_SUBVENTION_ETAT].url,
        accessToken: ``,
        tileGrid: createXYZ({
          minZoom: 5,
          maxZoom: 12
        })
      },
      zoomInterval: [5, 15],
      displayOptions: {
        label: "Communes avec subvention d'état"
      }
    },
    [SigmaVectorLayers.AMBASSADEURS]: {
      layerOptions: { zIndex: 15 },
      sourceOptions: {
        url: environment[SigmaVectorLayers.AMBASSADEURS].url,
        accessToken: ``,
        tileGrid: createXYZ({
          minZoom: 10,
          maxZoom: 15
        })
      },
      zoomInterval: [10, 15],
      displayOptions: {
        label: "Position des ambassadeurs et paladins"
      }
    },
    [SigmaVectorLayers.DEPARTEMENTS_BLANCS]: {
      layerOptions: { zIndex: 25 },
      sourceOptions: {
        url: environment[SigmaVectorLayers.DEPARTEMENTS_BLANCS].url,
        accessToken: ``,
        tileGrid: createXYZ({
          minZoom: 5,
          maxZoom: 10
        })
      },
      zoomInterval: [5, 10],
      displayOptions: {
        label: "Departements blancs"
      }
    },
    [SigmaVectorLayers.COMMUNES_BLANCHES_FROIDES]: {
      layerOptions: { zIndex: 25 },
      sourceOptions: {
        url: environment[SigmaVectorLayers.COMMUNES_BLANCHES_FROIDES].url,
        accessToken: ``,
        tileGrid: createXYZ({
          minZoom: 5,
          maxZoom: 15
        })
      },
      zoomInterval: [5, 15],
      displayOptions: {
        label: "Communes blanches froides"
      }
    },
    [SigmaVectorLayers.COMMUNES_BLANCHES_CHAUDES]: {
      layerOptions: { zIndex: 25 },
      sourceOptions: {
        url: environment[SigmaVectorLayers.COMMUNES_BLANCHES_CHAUDES].url,
        accessToken: ``,
        tileGrid: createXYZ({
          minZoom: 5,
          maxZoom: 15
        })
      },
      zoomInterval: [5, 15],
      displayOptions: {
        label: "Communes blanches Chaudes"
      }
    },
    [SigmaVectorLayers.ORANGE_4G]: {
      layerOptions: {
        zIndex: 26,
        style: [
          new Style({
            fill: new Fill({
              color: "rgba(124, 197, 171, 0.5)"
            }),
            stroke: new Stroke({
              color: "rgba(124, 197, 171, 1)",
              width: 2
            })
          })
        ]
      },
      sourceOptions: {
        url: environment[SigmaVectorLayers.ORANGE_4G].url,
        accessToken: ``,
        tileGrid: createXYZ({
          minZoom: 5,
          maxZoom: 15
        })
      },
      zoomInterval: [5, 20],
      displayOptions: {
        label: "4G Orange"
      }
    }
  },
  jsonTiles: {
    [SigmaTileJSONLayers.TEST]: {
      layerOptions: {
        zIndex: 16,
        visible: true
      },
      sourceOptions: {
        crossOrigin: "anonymous",
        url:
          "https://api.maptiler.com/tiles/634854ca-d492-43d3-8f98-46dc4d68899e/tiles.json?key={accessToken}",
        accessToken: ``,
        attributions:
          "&copy; <a href='https://www.maptiler.com/copyright/' target='_blank'>MapTiler</a> &copy; <a href='http://www.openstreetmap.org/about/' target='_blank'>OpenStreetMap</a> contributors"
      },
      displayOptions: {
        label: "for test only"
      }
    }
  },
  olms: {
    [SigmaOLMSStyles.FIBRE_IPE]: {
      url: environment[SigmaOLMSStyles.FIBRE_IPE].styleUrl,
      accessToken: ``,
      displayOptions: {
        label: "IPE Fibre",
        zIndex: 50
      }
    },
    [SigmaOLMSStyles.RADIO_COUVERTURE_AMENAGEURS]: {
      url: environment[SigmaOLMSStyles.RADIO_COUVERTURE_AMENAGEURS].styleUrl,
      accessToken: ``,
      displayOptions: {
        label: "Couverture Radio",
        zIndex: 18
      }
    },
    [SigmaOLMSStyles.FIBRE_IPE_CLIENT]: {
      url: environment[SigmaOLMSStyles.FIBRE_IPE_CLIENT].styleUrl,
      accessToken: ``,
      displayOptions: {
        label: "IPE Fibre",
        zIndex: 50
      }
    },
    [SigmaOLMSStyles.SIGMA_POI_ADP]: {
      url: environment[SigmaOLMSStyles.SIGMA_POI_ADP].styleUrl,
      accessToken: ``,
      displayOptions: {
        label: "Adresses sans IPE"
      }
    },
    [SigmaOLMSStyles.SIGMA_POI_ADP_CLIENT]: {
      url: environment[SigmaOLMSStyles.SIGMA_POI_ADP_CLIENT].styleUrl,
      accessToken: ``,
      displayOptions: {
        label: "Adresses sans IPE"
      }
    }
  },
  legend: null
};
