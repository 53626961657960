import TileJSON from "ol/source/TileJSON";
import { replaceAccessToken } from "../../../helpers/source-helper";
import { SigmaTileJSONSourceOptions } from "../../../typings/options.types";

export class SigmaTileJSONSource extends TileJSON {
  public constructor(options?: SigmaTileJSONSourceOptions) {
    if (options && options.url && options.accessToken) {
      options.url = replaceAccessToken(options.url, options.accessToken);
    }
    super({ ...options });
  }
}
