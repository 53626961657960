export enum SigmaVectorLayers {
  CADASTRE = "Cadastre",
  FIBRE_OUVERT_COMMUNES = "FibreOuvertCommunes",
  FIBRE_OUVERT_COMMUNES_CLIENT = "FibreOuvertCommunesClient",
  FIBRE_PLANIFIE_COMMUNES = "FibrePlanifieCommunes",
  FIBRE_PLANIFIE_COMMUNES_CLIENT = "FibrePlanifieCommunesClient",
  RADIO_OUVERT_COMMUNES = "RadioOuvertCommunes",
  RADIO_PLANIFIE_COMMUNES = "RadioPlanifieCommunes",
  PARTENAIRES_CERTIFIES_FIBRE = "PartenairesCertifiesFibre",
  PARTENAIRES_NON_CERTIFIES = "PartenairesNonCertifies",
  VILLES_SUBVENTION_ETAT = "VillesSubventionEtat",
  AMBASSADEURS = "Ambassadeurs",
  DEPARTEMENTS_BLANCS = "DepartementsBlancs",
  COMMUNES_BLANCHES_FROIDES = "CommunesBf",
  COMMUNES_BLANCHES_CHAUDES = "CommunesBc",
  ORANGE_4G = "Orange4G"
}
