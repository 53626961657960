import WMTS, { Options } from "ol/source/WMTS";
import { replaceAccessToken } from "../../../helpers/source-helper";
import { SigmaWMTSSourceOptions } from "../../../typings/options.types";

export class SigmaWMTSSource extends WMTS {
  public constructor(options?: Partial<SigmaWMTSSourceOptions>) {
    if (options && options.url && options.accessToken) {
      options.url = replaceAccessToken(options.url, options.accessToken);
    }
    super(options as Options);
  }
}
